<template>
  <div class="dummy-footer">
    <swiper :options="swiperOptions">
      <swiper-slide v-for="(item, i) in histroy" :key="'list' + i">
        <div class="flex target">
          <div>
            <img class="img" src="@/assets/images/heatmap/round.png" alt="" />
          </div>
          <div class="item flex flex-direction">
            <div class="number">
              {{ item.num }}
            </div>
            <!-- <animate-number
              from="0"
              :to="item.num"
              :key="item.num"
              class="number"
            ></animate-number> -->
            <div class="flex">
              <span class="name">{{ item.name }}</span>
              <span class="uuid">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// import { fontSize48 } from './comm'
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  data() {
    return {
      histroy: [
        {
          name: "累计参与次数",
          num: 0,
          unit: "(次)",
        },
        {
          name: "累计完成次数",
          num: 0,
          unit: "(次)",
        },
        {
          name: "完成率",
          num: 0,
          unit: "",
        },
        {
          name: "累计补贴",
          num: "",
          unit: "(元)",
        },
        {
          name: "累计响应容量",
          num: 0,
          unit: "(MW)",
        },
        {
          name: "减少碳排放",
          num: 0,
          unit: "(吨)",
        },
      ],
      swiperOptions: {
        loop: true,
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        autoplay: false,
        speed: 500,
        slidesPerView: 5,
        spaceBetween: 140,
        centeredSlides: true,
        watchSlidesProgress: true,
        // on: {
        //   setTranslate: function () {
        //     let slides = this.slides;
        //     for (let i = 0; i < slides.length; i++) {
        //       let slide = slides.eq(i);
        //       let progress = slides[i].progress;
        //       slide.css({
        //         opacity: "",
        //         background: "",
        //       });
        //       slide.transform("");
        //       slide.css("opacity", 1 - Math.abs(progress) / 6);
        //       slide.transform(
        //         "translate3d(0," + Math.abs(progress) * 20 + "px, 0)"
        //       );
        //     }
        //   },
        //   setTransition: function (transition) {
        //     for (let i = 0; i < this.slides.length; i++) {
        //       let slide = this.slides.eq(i);
        //       slide.transition(transition);
        //     }
        //   },
        // },
      },
    };
  },
  mounted() {
    this.zongheAll();
  },
  methods: {
    // 综合态势累计
    zongheAll() {
      let params = {
        enCode: "powerplant-left-ljcyfhtjqk",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "历史参与与负荷调节情况");
          this.histroy[0].num = Number(res[0].acc_active_num).toFixed(0) || 0;
          this.histroy[1].num = Number(res[0].acc_finish_num).toFixed(0) || 0;
          this.histroy[2].num =
            Number(res[0].finish_rate).toFixed(0) + "%" || "0";
          this.histroy[3].num =
            Number(res[0].acc_subsidy).toFixed(0) + "万" || "0";
          this.histroy[4].num =
            Number(res[0].cumulative_response_capacity).toFixed(0) || 0;
          this.histroy[5].num = Number(res[0].carbon_reduction).toFixed(0) || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
// import { fontSize48 } from './comm'
// import {
// 	dataInterface
// } from "@/api/dataInterfaceApi"
// export default {
// 	data() {
// 		return {
// 			histroy: [{
// 				name: '累计参与次数',
// 				num: '1212',
// 				unit: '次'
// 			}, {
// 				name: '累计完成次数',
// 				num: '1212',
// 				unit: '次'
// 			}, {
// 				name: '完成率',
// 				num: '1212',
// 				unit: '%'
// 			}, {
// 				name: '累计补贴',
// 				num: '1212',
// 				unit: '万元'
// 			}, {
// 				name: '累计响应容量',
// 				num: '1212',
// 				unit: 'MW'
// 			}, {
// 				name: '减少碳排放',
// 				num: '1212',
// 				unit: '吨'
// 			}],
// 			swiperOptions: {
// 				loop: true,
// 				autoplay: {
// 					delay: 2000,
// 					disableOnInteraction: false
// 				},
// 				speed: 500,
// 				slidesPerView: 5,
// 				spaceBetween: 140,
// 				centeredSlides: true,
// 				watchSlidesProgress: true,
// 				on: {
// 					setTranslate: function() {
// 						let slides = this.slides;
// 						for (let i = 0; i < slides.length; i++) {
// 							let slide = slides.eq(i)
// 							let progress = slides[i].progress
// 							slide.css({
// 								'opacity': '',
// 								'background': ''
// 							})
// 							slide.transform('')
// 							slide.css('opacity', (1 - Math.abs(progress) / 6))
// 							slide.transform('translate3d(0,' + Math.abs(progress) * 20 + 'px, 0)')
// 						}
// 					},
// 					setTransition: function(transition) {
// 						for (let i = 0; i < this.slides.length; i++) {
// 							let slide = this.slides.eq(i)
// 							slide.transition(transition)
// 						}
// 					}
// 				},
// 			}
// 		}
// 	},
// 	mounted() {
// 		this.zongheAll()
// 	},
// 	methods: {
// 		// 综合态势累计
// 		zongheAll() {
// 			let params = {
// 				enCode: "powerplant-left-ljcyfhtjqk",
// 			};
// 			dataInterface
// 				.dataFaceApi(params)
// 				.then((res) => {
// 					console.log(res, '历史参与与负荷调节情况')
// 					this.histroy[0].num = res[0].acc_active_num || '-'
// 					this.histroy[1].num = res[0].acc_finish_num || '-'
// 					this.histroy[2].num = res[0].finish_rate || '-'
// 					this.histroy[3].num = res[0].acc_subsidy || '-'
// 					this.histroy[4].num = res[0].cumulative_response_capacity || '-'
// 					this.histroy[5].num = res[0].carbon_reduction || '-'
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 				});
// 		}
// 	}

// }
// import {
// 	dataInterface
// } from '@/api/dataInterfaceApi';
// export default {
// 	components: {},
// 	data() {
// 		let mIcon = require('@/assets/images/exposition/juheshang.png')
// 		return {
// 			photovoltaic: {},
// 			now: '日',
// 			target: [{
// 					icon: mIcon,
// 					label: '需求响应邀约',
// 					number: '',
// 					unit: '个'
// 				},
// 				{
// 					icon: mIcon,
// 					label: '执行主体数量',
// 					number: '',
// 					unit: '个'
// 				},
// 				{
// 					icon: mIcon,
// 					label: '执行指令下发',
// 					number: '',
// 					unit: '条'
// 				},
// 				{
// 					icon: mIcon,
// 					label: '有效节点率',
// 					number: '',
// 					unit: '%'
// 				},
// 				// {
// 				// 	icon: mIcon,
// 				// 	label: '收益测算',
// 				// 	number: '',
// 				// 	unit: '万元'
// 				// }
// 			],
// 			target1: [{
// 					icon: mIcon,
// 					label: '辅助服务邀约',
// 					number: '',
// 					unit: '个'
// 				},
// 				{
// 					icon: mIcon,
// 					label: '执行主体数量',
// 					number: '',
// 					unit: '个'
// 				},
// 				{
// 					icon: mIcon,
// 					label: '执行命令下发',
// 					number: '',
// 					unit: '条'
// 				},
// 				{
// 					icon: mIcon,
// 					label: '有效节点率',
// 					number: '',
// 					unit: '%'
// 				},
// 				// {
// 				// 	icon: mIcon,
// 				// 	label: '收益测算',
// 				// 	number: '',
// 				// 	unit: '万元'
// 				// }
// 			],
// 			province: '32',
// 			show1: false,
// 			show2: false,
// 		}
// 	},
// 	destroyed() {
// 		this.$bus.$off('updateDummyFn')
// 	},
// 	mounted() {
// 		this.$bus.$on('updateDummyFn', () => {
// 			this.getphotovoltaic()
// 			this.getData()
// 		})
// 		this.getphotovoltaic()
// 		this.getData()
// 	},
// 	methods: {
// 		nowType() {
// 			return localStorage.getItem('typeTime') || '日'
// 		},
// 		getphotovoltaic() {
// 			this.show1 = false
// 			let params = {
// 				"enCode": "powerplant-mid-xuqiuxiangying",
// 				"@province": localStorage.getItem('updateDummyPcode') ? localStorage.getItem('updateDummyPcode') :
// 					this.province
// 			}
// 			dataInterface.dataFaceApi(params).then((res) => {
// 				console.log(res, '响应主体分布')
// 				let data = res[0] || {}
// 				this.target[0].number = data.invitation_cnts || 0
// 				this.target[1].number = data.associated_cnts || 0
// 				this.target[2].number = data.executive_instruction_delivery || 0
// 				this.target[3].number = data.effective_annual_node_rate || 0
// 				this.target[4].number = data.cumulative_subsidy || 0
// 				this.show1 = true
// 			}).catch((err) => {
// 				console.log(err);
// 			});
// 		},
// 		getData() {
// 			this.show2 = false
// 			let params = {
// 				"enCode": "powerplant-mid-fuzhufuwu",
// 				"@province": localStorage.getItem('updateDummyPcode') ? localStorage.getItem('updateDummyPcode') :
// 					this.province
// 			}
// 			dataInterface.dataFaceApi(params).then((res) => {
// 				console.log(res, '响应主体分布111111111111111111111')
// 				console.log(this.target1, '响应主体分布111111111111111111111')
// 				let data = res[0] || {}
// 				this.target1[0].number = data.invitation_cnts || 0
// 				this.target1[1].number = data.associated_cnts || 0
// 				this.target1[2].number = data.executive_instruction_delivery || 0
// 				this.target1[3].number = data.effective_annual_node_rate || 0
// 				this.target1[4].number = data.cumulative_subsidy || 0
// 				this.show2 = true
// 			}).catch((err) => {
// 				console.log(err);
// 			});
// 		}
// 	}
// }
</script>

<style lang="scss" scoped>
// @font-face {
// 	font-family: 'DS-Digital-Bold';
// 	src: url('~@/assets/font/DS-Digital-Bold.ttf');
// }
// .DS-Digital-Bold {
// 	font-family: "DS-Digital-Bold";
// }
// .notice-box {

// 	width: 100%;
// 	height: 150px;
// 	margin-bottom: 30px;
// 	background: url('~@/assets/images/exposition/dummyNotice.png') no-repeat;
// 	background-size: 100% 100%;
// 	font-size: $FontSize36;
// 	padding-left: 160px;
// 	display: flex;

// 	.notice-box-title {
// 		width: 190px;
// 		line-height: 150px;
// 		// background-color: pink;
// 		text-align: center;
// 		padding-right: 10px;
// 	}

// 	.notice-box-cell {
// 		width: 270px;
// 		// text-align: center;
// 		font-size: $FontSize26;
// 		margin-top: 40px;
// 		padding: 0 10px 0 40px;
// 		background: url('~@/assets/images/exposition/link.png') no-repeat;
// 		background-size: 23px 45px;
// 		background-position: 20px 8px;
// 	}
// }
// .my-footer {
// 	.item {
// 		font-size: $FontSize20;
// 		position: relative;

// 		.item-c {
// 			width: 125px;
// 			height: 114px;
// 			margin-top: -10px;
// 		}

// 		.item-c-at {
// 			width: 180px;
// 			height: 159px;
// 			margin-top: -10px;
// 		}

// 		.item-v {
// 			font-size: $FontSize20;
// 			height: 70px;

// 			>span {
// 				font-size: $FontSize48;
// 				@include ffamily-DS;
// 			}
// 		}

// 		.item-l {
// 			font-size: $FontSize28;
// 			color: #C6D1DB;
// 		}
// 	}
// }
</style>
<style lang="scss" scope>
.dummy-footer {
  .swiper-container {
    height: 100%;
    // background-color: pink;
    width: 2729.58px;
    // height: 215.37px;
    .swiper-wrapper {
      // margin-top: 40px;
    }
  }

  .swiper-slide {
    .item-c-at {
      display: none;
    }
  }

  .swiper-slide-active {
    .item {
      .item-c {
        display: none;
      }

      .item-c-at {
        display: block;
      }

      .item-l {
        font-size: $FontSize36;
      }

      .item-v {
        height: 120px;
        margin-top: -80px;

        > span {
          color: #00fdf4;
          font-size: $FontSize96;
        }
      }
    }
  }

  .target {
    width: 610px;
    height: 250px;
    // background-color:pink;
    .img {
      width: 207px;
      height: 207px;
      margin-top: 30px;
    }

    .item {
      margin-top: 40px;
      margin-left: -120px;

      .number {
        color: $FontColor1;
        font-size: $FontSize64;
        @include ffamily-HT;
        padding-left: 30px;
      }
      .name {
        padding-left: 30px;
      }
      .name,
      .uuid {
        display: block;
        color: #fff;
        height: 40px;
        line-height: 20px;
        // padding-left: 30px;
        font-size: $FontSize36;
      }
    }
  }
}
</style>