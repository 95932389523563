<template>
	<div class="page-part-bottom-cell-box">
		<div style="width: 100%; height: 80px">
			<MyTitle title="直控资源" style="font-size:48px;z-index=100000" />
		</div>
		<div class="page-part-bottom-cell mt-80">
			<img class="imgDummyBorder" src="~@/assets/images/exposition/dummyBorder.png" alt="" />
			<img class="img" src="~@/assets/images/exposition/dummybottom01.png" alt="" />
			<div class="top">
				<div class="title-name">商用站</div>
				<div class="label labelone">20家</div>
				<div class="label labelonetwo">60MW</div>
			</div>
			<div class="bottom">
				<!-- <div class="left">
				<img class="img" src="~@/assets/images/exposition/dummybottom-1.png" alt=""> -->
				<!-- </div> -->
				<div class="right">
					<div class="notice-cell">
						<div class="notice-cell-left">徐州两山口站</div>
						<div class="notice-cell-right">100kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">徐州琵琶山重卡站</div>
						<div class="notice-cell-right">750kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">徐州新城区站</div>
						<div class="notice-cell-right">1000kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">徐州长山站</div>
						<div class="notice-cell-right">800kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">徐州高新区站</div>
						<div class="notice-cell-right">1000kW</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-part-bottom-cell mt-80">
			<img class="imgDummyBorder" src="~@/assets/images/exposition/dummyBorder.png" alt="" />
			<img class="img" src="~@/assets/images/exposition/dummybottom2.png" alt="" />
			<div class="top">
				<div class="title-name">储能站</div>
				<div class="label labelone">8家</div>
				<div class="label labelonetwo">22MW</div>
			</div>
			<div class="bottom">
				<!-- <div class="left">
				<img class="img" src="~@/assets/images/exposition/dummybottom-1.png" alt=""> -->
				<!-- </div> -->
				<div class="right">
					<div class="notice-cell">
						<div class="notice-cell-left">杭州龙泉路储充站</div>
						<div class="notice-cell-right">400kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">深圳梅林路光储充站</div>
						<div class="notice-cell-right">400kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">苏州铁塔能源中心</div>
						<div class="notice-cell-right">400kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">嫦娥1号(苏州光伏)</div>
						<div class="notice-cell-right">10000kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">嫦娥2号(四洲)</div>
						<div class="notice-cell-right">2000kW</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-part-bottom-cell mt-80">
			<img class="imgDummyBorder" src="~@/assets/images/exposition/dummyBorder.png" alt="" />
			<img class="img" src="~@/assets/images/exposition/dummybottom3.png" alt="" />
			<div class="top">
				<div class="title-name">充电站</div>
				<div class="label labelone">20家</div>
				<div class="label labelonetwo">10MW</div>
			</div>
			<div class="bottom">
				<!-- <div class="left">
				<img class="img" src="~@/assets/images/exposition/dummybottom-1.png" alt=""> -->
				<!-- </div> -->
				<div class="right">
					<div class="notice-cell">
						<div class="notice-cell-left">徐州高新区站</div>
						<div class="notice-cell-right">500kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">徐州新城区站</div>
						<div class="notice-cell-right">400kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">南京双龙大道站</div>
						<div class="notice-cell-right">300kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">苏州星龙街站</div>
						<div class="notice-cell-right">300kW</div>
					</div>
					<div class="notice-cell">
						<div class="notice-cell-left">苏州铁塔能源中心</div>
						<div class="notice-cell-right">200kW</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MyTitle from "@/views/components/MyTitle.vue";
	export default {
		components: {
			MyTitle,
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/comm.scss';

	.page-part-bottom-cell-box {
		//   background-color: pink;
		width: 1470px;
		height: 1153px;
		padding-right: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.page-part-bottom-cell {
			width: 456px;
			height: 1050px;
			// background: url("~@/assets/images/exposition/dummytitle.png") no-repeat;
			background-size: 100% 100%;
			// z-index: 99999;
			position: relative;

			.imgDummyBorder {
				display: block;
				position: absolute;
				top: -2px;
				left: -13px;
				width: 105%;
				height: 623px;
				z-index: 10000;
			}

			.img {
				display: block;
				position: absolute;
				top: 10px;
				left: 0;
				width: 100%;
				height: 586px;
			}

			.top {
				position: absolute;
				height: 80px;
				width: 100%;
				display: flex;
				bottom: 500px;
				left: 0;
				background: url("~@/assets/images/exposition/dummy05.png") no-repeat;
				z-index: 100000;

				//
				.title-name {
					margin-top: 10px;
					height: 80px;
					line-height: 40px;
					padding-left: 40px;
					font-size: $FontSize32;
					flex: 1;
					padding-bottom: 5px;
				}

				.label {
					width: 120px;
					height: 40px;
					line-height: 40px;
					font-size: $FontSize32;
					text-align: center;
					margin-top: 10px;
					background: url("~@/assets/images/exposition/dummynumber.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 28px;
				}
			}

			.bottom {
				height: 300px;
				width: 100%;
				padding: 30px;
				display: flex;

				.left {
					width: 280px;
					height: 190px;
					// background-color: yellow;
				}

				.right {
					width: 100%;
					position: absolute;
					bottom: 60px;
					left: 10px;
					z-index: 10;
					flex: 1;
					// padding-left: 10px;

					.notice-cell {
						height: 60px;
						line-height: 60px;
						font-size: $FontSize32;
						display: flex;
						margin-bottom: 20px;
						// padding: 0 15px;

						.notice-cell-left {
							// flex: 1;
							width: 270px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.notice-cell-right {
							width: 130px;
							padding-left: 30px;
							color: #00FDF4;
						}
					}

					.notice-cell:nth-child(even) {
						width:100%;
						background: rgba(#051314,.3);
					}
				}
			}
		}
	}
</style>