<template>
  <!-- <divBox2 :title="'充放量趋势'"></divBox2> -->
  <div style="width: 790px; height: 380px">
    <MyEcharts :visible="showcdata" :id="'dummyshouyiqushi'" :options="cdata" />
  </div>
</template>

<script>
import MyEcharts from "@/views/components/charts/echarts.vue";
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    // divBox2,
    MyEcharts,
  },
  data() {
    return {
      showcdata: false,
      // title:"车辆新增及活跃趋势",
      cdata: {
        title: {
          text: "万MWh",
          left: -5,
          top: 30,
        },
        // color: ['#0A9ABE','#FFD15C','#54CBCD','#FFD15C'],
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
              // data: ['发电量', '收益', '周平均发电量', '周平均收益'],
            },
          },
        },
        grid: {
          left: "0%", // 左边距
          right: "5%", // 右边距
          bottom: "", // 底边距
          //   top: "10%", // 顶边距
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: [
          {
            // name: 'MWh',
            type: "value",
            splitLine: true,
          },
          {
            name: "",
            type: "value",
            splitLine: true,
            itemStyle: {
              textAlign: "center",
            },
          },
        ],
        series: [
          {
            name: "辅助服务响应量",
            type: "bar",
            smooth: true,
            data: [],
            barWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(14, 202, 250,0)", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#4F8096", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#0ECAFA", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            // tooltip: {
            // 	valueFormatter: function (value) {
            // 		return value + ' kWh';
            // 	}
            // },
          },
          {
            name: "需求响应响应量",
            type: "bar",
            smooth: true,
            barWidth: 8,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#15F5C9", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#1E927C", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(21, 245, 201,0)", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            // tooltip: {
            // 	valueFormatter: function (value) {
            // 		return value + ' kWh';
            // 	}
            // },
          },
        ],
      },
    };
  },
  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getBattery24hours();
    });
    this.getBattery24hours();
  },
  methods: {
    //获取24小时电次数分布
    getBattery24hours() {
      let params = {
        enCode: "powerplant-left-shouyiqushi",
        // "@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
        // "@dim_date": localStorage.getItem("changeDataNuber") || timer,
      };
      this.showcdata = false;
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "收益趋势");
          if (res) {
            let data = [];
            let data2 = [];
            let xAxis = [];
            let data3 = [];
            let data4 = [];
            res.forEach((item) => {
              xAxis.push(item.cur_date);
              data.push(item.fzfw_actual_response_capacity);
              data2.push(item.fzfw_actual_income);
              data3.push(item.xqxy_actual_response_capacity);
              data4.push(item.xqxy_actual_income);
            });
            console.log(data);
            this.cdata.xAxis.data = xAxis;
            this.cdata.series[0].data = data;
            this.cdata.series[1].data = data3;
            this.showcdata = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
.main-box-diangganggaikuang {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .main-box-cell {
    margin-bottom: 6px;
    display: flex;
    width: 200px;
    height: 100px;
    padding: 0 10px;

    .main-box-left {
      img {
        width: 80px;
        height: 80px;
      }
    }

    .main-box-right {
      padding-left: 15px;

      .top {
        height: 50px;
        line-height: 70px;
      }

      .bottom {
        height: 50px;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
</style>