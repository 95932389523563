<template>
    <div class="my-header">
        <img class="img" :src="myHeader" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {
            myHeader: require('@/assets/images/myComm/header.png')
        }
    },
    mounted() {
        let { name } = this.$route
        if(['chonghuan-home', 'chonghuan-monitor', 'chonghuan-station'].includes(name)) {
            this.myHeader = require('@/assets/images/myComm/header-1.png')
            document.title = '铁塔数字能源充换电一体化'
        }
        if(['chonghuan-monitor'].includes(name)) {
            this.myHeader = require('@/assets/images/myComm/header-5.png')
            document.title = '铁塔数字能源光储充换算'
        }
        // 车算云
        else if(['expositionRight'].includes(name)) {
            this.myHeader = require('@/assets/images/myComm/header-2.png')
            document.title = '铁塔数字能源光储充换车算云'
        }
        // 零碳交通
        else if(['vehicles-home','heatmap-home','vehicletype-home','batterypack-home'].includes(name)) {
            this.myHeader = require('@/assets/images/myComm/header-3.png')
            document.title = '铁塔数字能源零碳交通'
        }
        // 虚拟电厂
        else if(['dummy'].includes(name)) {
            this.myHeader = require('@/assets/images/myComm/header-4.png')
            document.title = '铁塔数字能源虚拟电厂VPP'
        }       
    }
}
</script>

<style lang="scss" scoped>
.my-header {
    width: 100%;
    height: $HeaderHeight;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: url('~@/assets/images/myComm/header-bg.png') no-repeat;
    background-size: 100%;
    > .img {
        position: absolute;
        width: 900px;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -58%);
    }
}
</style>