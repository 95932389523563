<template>
  <div class="page flex flex-direction">
    <div class="itemBox flex mt-40 justify-content-between">
      <div
        class="flex flex-direction align-items"
        v-for="(item, index) in target"
        :key="'target' + index"
      >
        <!-- <div class="number">{{ item.number }}</div> -->
        <animate-number
          from="0"
          :to="item.number"
          :key="item.number"
          class="number"
        ></animate-number>
        <div class="unit mt-20">{{ item.unit }}</div>
        <div>
          <img
            class="itemImg"
            :src="require('@/assets/images/exposition/chd-bg-dummy.png')"
            alt=""
          />
        </div>
        <div class="label mt-20">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  data() {
    let mIcon = require("@/assets/images/exposition/juheshang.png");
    return {
      photovoltaic: {},
      now: "日",
      target: [
        {
          icon: mIcon,
          label: "聚合商",
          number: 0,
          unit: "家",
        },
        {
          icon: mIcon,
          label: "主体数量",
          number: 0,
          unit: "户",
        },
        {
          icon: mIcon,
          label: "设备资源",
          number: 0,
          unit: "台",
        },
      ],
    };
  },
  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getphotovoltaic();
    });
    this.getphotovoltaic();
  },
  methods: {
    nowType() {
      return localStorage.getItem("typeTime") || "日";
    },
    getphotovoltaic() {
      let params = {
        enCode: "powerplant-left-zyztgs",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "");
          let data = res[0] || {};
          this.target[0].number = data.integrator_num || 0;
          this.target[1].number = data.associated_num || 0;
          this.target[2].number = data.equipment_num || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
  .itemBox {
    height: 268px;
    // background: skyblue;
    .itemImg {
      // margin-top: -50px;
      animation: breath_light 3s ease infinite;
    }
    .number {
      font-size: $FontSize64;
      @include ffamily-DS;
    }
    .unit {
      font-size: $FontSize32;
      line-height: 0px;
      // @include ffamily-HT;
    }
    .label {
      width: 200px;
      font-size: $FontSize32;
      text-align: center;
      background: url("~@/assets/images/exposition/chd-bg-bottom.png");
      background-size: 100% 100%;
    }
  }
}
</style>