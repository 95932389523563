<template>
	<div class="my-charts">
		<div v-if="!visible" class="charts-loading flex align-items justify-content">
			<img
				class="img"
				:style="{'width': `${imgW}px`, 'height': `${imgW}px`}"
				:src="require('@/assets/images/comm/loading.png')" alt="">
		</div>
		<div
			class="charts-box"
			:class="{'show': visible}"
			:id="id"></div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			id: {
				type: String,
				default: `echarts${Math.ceil(Math.random() * 100330)}`
			},
			width: {
				type: Number,
				default: 0
			},
			height: {
				type: Number,
				default: 0
			},
			options: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				myCharts11: null,
				imgW: 100
			}
		},
		watch: {
			visible(newV) {
				let that = this
				if(newV) {
					setTimeout(() => {
						that.myCharts()
					}, 100)
				}
			},
			options: {
				handler(newVal) {
					let that = this
					if (newVal && that.myCharts11) {
						that.myCharts11.setOption(newVal, true)
						// this.myCharts()
					}
				},
				immediate: true,
				deep: true
			}
		},
		mounted() {
			let dome = document.getElementById(this.id)
			let dw = dome.offsetWidth
			let dh = dome.offsetHeight
			this.imgW = (dw > dh ? dh : dw) * 0.5
		},
		methods: {

			myCharts() {
				const _this = this
				const myCharts = echarts.init(document.getElementById(_this.id), 'dark')

				if (_this.width && _this.height) {
					myCharts.resize({
						width: `${_this.width}px`,
						height: `${_this.height}px`
					})
				}
				let options = JSON.parse(JSON.stringify(_this.options))
				// let options = _this.options
				this.myCharts11 = myCharts
				// 隐藏背景色
				options.backgroundColor = 'rgba(0, 0, 0, 0)'
				// title 设置
				if (options.title) {
					if (!options.title.textStyle) {
						options.title.textStyle = {}
						this.options.title.textStyle = {}
					}
					options.title.textStyle.fontSize = this.options.title.textStyle.fontSize || '18'
					options.title.textStyle.color = this.options.title.textStyle.color || '#C4D3E1'

					options.title.top = this.options.title.top || '3%'
					options.title.left = this.options.title.left || '2%'
				}
				// // 隐藏x轴刻度和线
				if (options.xAxis instanceof Array) {
					options.xAxis = options.xAxis.map(x => {
						x.axisTick = {
							show: false,
						}
						x.axisLine = {
							show: false
						}
						if(!x.axisLabel) {
							x.axisLabel = {}
						}
						x.axisLabel.fontSize = 18
						return x
					})
				}
				if (options.xAxis instanceof Object) {
					options.xAxis.axisTick = {
						show: false,
					}
					options.xAxis.axisLine = {
						show: false
					}
					if (!options.xAxis.axisLabel) {
						options.xAxis.axisLabel = {}
					}
					options.xAxis.axisLabel.fontSize = 18
				}
				// 隐藏Y轴刻度和线 + y轴分割线
				if (options.yAxis instanceof Array) {
					options.yAxis = options.yAxis.map(y => {
						y.axisTick = {
							show: false,
						}
						y.axisLine = {
							show: false
						}
						y.splitLine = {
							show: false,
						}
						y.nameTextStyle = {
							align: 'right'
						}
						if(!y.axisLabel) {
							y.axisLabel = {}
						}
						y.axisLabel.fontSize = 18
						return y
					})
				}
				if (options.yAxis instanceof Object) {
					options.yAxis.axisTick = {
						show: false
					}
					options.yAxis.axisLine = {
						show: false
					}
					options.yAxis.splitLine = {
						show: false
					}
					options.yAxis.nameTextStyle = {
						align: 'right'
					}
					if(!options.yAxis.axisLabel) {
						options.yAxis.axisLabel = {}
					}
					options.yAxis.axisLabel.fontSize = 18
				}
				// tooltip 设置
				if (!options.tooltip) {
					options.tooltip = {}
				}
				let tooltip = this.options.grid || {}
				options.tooltip.trigger = tooltip.trigger || 'axis',
					options.tooltip.backgroundColor = tooltip.backgroundColor || "rgba(0,10,48,0.7)",
					options.tooltip.borderWidth = "0", //边框宽度设置1
					options.tooltip.borderColor = "none", //设置边框颜色
					options.tooltip.textStyle = {
						color: "#C4D3E1" //设置文字颜色
					}
				// grid 设置
				if (!options.grid) {
					options.grid = {}
				}
				let grid = this.options.grid || {}
				options.grid.top = grid.top || '20%'
				options.grid.left = grid.left || '3%',
					options.grid.right = grid.right || '4%',
					options.grid.bottom = grid.bottom || '3%',
					options.grid.containLabel = grid.containLabel || true

				// 去掉折线的点
				let thisSeries = this.options.series
				if (options.series instanceof Object) {
					options.series.showSymbol = false
					options.series.barWidth = options.series.barWidth || 10
					// legend icon 颜色
					if (!options.series.itemStyle) {
						options.series.itemStyle = {}
						options.series.itemStyle.color = options.series.lineStyle && options.series.lineStyle.color || {}
					} else {
						options.series.itemStyle = thisSeries.itemStyle || {}
					}
					// tooltip 单位
					options.series.tooltip = thisSeries && thisSeries.tooltip || {}
				}
				if (options.series instanceof Array) {
					options.series.map((item, i) => {
						item.showSymbol = false
						item.barWidth = item.barWidth || 10
						// legend icon 颜色
						if (!item.itemStyle) {
							item.itemStyle = {}
							item.itemStyle.color = thisSeries[i].lineStyle && thisSeries[i].lineStyle.color || {}
						} else {
							item.itemStyle = thisSeries[i].itemStyle
						}
						// tooltip 单位
						item.tooltip = thisSeries[i] && thisSeries[i].tooltip || {}
					})
				}
				// legend 设置
				let legend = this.options.legend || {}
				if (!options.legend) {
					options.legend = {}
				}
				if (!legend.hidden) {
					options.legend.right = legend.right || 'left'
					options.legend.itemWidth = legend.itemWidth || 12
					options.legend.itemHeight = legend.itemHeight || 8
					options.legend.icon = legend.icon || 'rect'
					options.legend.textStyle = legend.textStyle || {
						color: '#C4D3E1',
						fontSize: 18
					}
				} else {
					options.legend.show = false
				}

				myCharts.setOption(options)
			}
		}
	}
</script>

<style lang="scss" scoped>
.my-charts {
	width: 100%;
	height: 100%;
	.charts-box, .charts-loading {
		width: 100%;
		height: 100%;
	}
	.charts-box {
		opacity: 0;
		&.show {
			opacity: 1;
		}
	}
	.img {
		width: 100%;
		height: 100%;
	}
}
</style>