<template>
	<div class="screen-wrapper">
		<div class="screenBg">
			<div class="dummynotice-box">
				<dummynotice></dummynotice>
			</div>
			<div class="cover-home cover"></div>
			<MyHeader></MyHeader>
			<div style="width: 3840px;height: 1590px;">
				<vehicles3D />
			</div>
			<div class="dummy-left">
				<div class="page-left flex flex-direction">
					<div class="page-part">
						<MyTitle title="资源分布" />
						<dummyzhengtigaikuang></dummyzhengtigaikuang>
					</div>
					<div class="page-part">
						<MyTitle title="响应量统计" type="2" />
						<dummyshouyiqushi></dummyshouyiqushi>
					</div>
					<div class="page-part ">
						<MyTitle title="调节能力" type="2" />
						<dummytiaojieqianli></dummytiaojieqianli>
					</div>
				</div>
			</div>
			<div class="dummy-right">
				<div class="page-right flex flex-direction">
					<div class="page-part-1">
						
					</div>
					<div class="page-part-bottom">
						<dummybottom></dummybottom>
					</div>
				</div>
			</div>
			<div class="dummy-footer-box">
				<dummyfooter></dummyfooter>
			</div>
		</div>
	</div>
</template>

<script>
	import MyTitle from '@/views/components/MyTitle.vue'
	import vehicles3D from '../components/map/dummy3D.vue'
	import MyHeader from '@/views/components/MyHeader.vue'
	import dummyzhengtigaikuang from '@/views/components/exposition/dummyzhengtigaikuang.vue'
	import dummyshouyiqushi from '@/views/components/exposition/dummyshouyiqushi.vue'
	import dummytiaojieqianli from '@/views/components/exposition/dummytiaojieqianli.vue'
	import dummyfooter from '@/views/components/exposition/dummyfooter.vue'
	import dummynotice from '@/views/components/exposition/dummynotice.vue'
	import dummybottom from '@/views/components/exposition/dummybottom.vue'
	export default {
		components: {
			MyTitle,
			vehicles3D,
			MyHeader,
			dummyzhengtigaikuang,
			dummyshouyiqushi,
			dummytiaojieqianli,
			dummyfooter,
			dummynotice,
			dummybottom
		},
		data() {
			return {
				pageInterval: null,
				intervalNum: 180,
				intervalNumSet: 180,
				fromUser: ''
			}
		},
		beforeCreate() {
			if(this.$route.query.fromScreen) {
				sessionStorage.setItem('fromScreen', this.$route.query.fromScreen)
			}
			let fromPath = localStorage.getItem('bigScreen::fromPath')
			let playCount = parseInt(localStorage.getItem('bigScreen::playCount') || 0, 10)
			if (fromPath && ++playCount >= 2) {
				localStorage.setItem('bigScreen::playCount', '0')
				localStorage.setItem('bigScreen::fromPath', '')
				location.reload()
			} else {
				localStorage.setItem('bigScreen::playCount', playCount + '')
			}
		},
		mounted() {
			console.log('this.$route===========', this.$route)
			let {
				fromUser,
				scaleY
			} = this.$route.query
			fromUser ? sessionStorage.setItem('fromUser', fromUser) : ''
			scaleY ? localStorage.setItem('bigScreen::scaleY', scaleY) : ''
			// let that = this
			this.fromUser = sessionStorage.getItem('fromUser')
			document.title = '移动能源光储充换车云一体化大屏'
			// // //初始化自适应  ----在刚显示的时候就开始适配一次
			// // that.handleScreenAuto();
			// //绑定自适应函数   ---防止浏览器栏变化后不再适配
			// window.onresize = (function() {
			// 	that.handleScreenAuto()
			// });

			this.pageInterval = null
			// if (sessionStorage.getItem('fromUser') != 'guizhou' && sessionStorage.getItem('fromUser') != 'suzhou') {
			// 	this.pageInterval = setInterval(() => {
			// 		this.intervalNum--
			// 		if (this.intervalNum < 0) {
			// 			// let localName = localStorage.getItem('exposition::pathName')
			// 			// this.$router.push({
			// 			// 	path: `/${localName || 'changingElectricity'}`
			// 			// })
			// 			this.$router.replace({
			// 				path: 'powerStation',
			// 				query: {
			// 					stationId: 'd13e09fd3c89459789d3933008d4bcfa'
			// 				}
			// 			})
			// 		}
			// 	}, 1000)

			// 	document.onmousemove = (() => {
			// 		// console.log(event)
			// 		this.intervalNum = this.intervalNumSet
			// 	})
			// }

		},
		deleted() {
			window.onresize = null;
		},
		methods: {
			// //数据大屏自适应函数
			// handleScreenAuto() {
			// 	const designDraftWidth = 3810; //设计稿的宽度
			// 	const designDraftHeight = 1590; //设计稿的高度
			// 	//根据屏幕的变化适配的比例
			// 	const scale = document.documentElement.clientWidth / document.documentElement.clientHeight <
			// 		designDraftWidth / designDraftHeight ?
			// 		(document.documentElement.clientWidth / designDraftWidth) :
			// 		(document.documentElement.clientHeight / designDraftHeight);
			// 	//缩放比例

			// 	console.log(scale)

			// 	// document.querySelector('#screen').style.transform = `scale(${scale}) translate(-50%)`;
			// 	let scaleY = parseFloat(localStorage.getItem('bigScreen::scaleY')) || 0.98
			// 	if (sessionStorage.getItem('fromUser') == 'guizhou') {
			// 		document.querySelector('#screen').style.transform = `scale(${scale+0.005}, ${scale * 1.6}) translate(-50%)`;
			// 	} else {
			// 		document.querySelector('#screen').style.transform =
			// 			`scale(${scale+0.005}, ${scale * scaleY}) translate(-50%)`;
			// 	}

			// }
		},
		destroyed() {
			clearInterval(this.pageInterval)
		}
	}
</script>

<style lang="scss" scope>
	@import '@/assets/scss/pc.scss';
	@import '@/assets/scss/main-map.scss';

	@font-face {
		font-family: 'DS-Digital-Bold';
		src: url('~@/assets/font/DS-Digital-Bold.ttf');
	}

	.screen-wrapper {
		height: 100%;
		width: 100%;
		font-size: 16px;
		color: #ffffff;
		left: 0;
		.DS-Digital-Bold {
			font-family: "DS-Digital-Bold";
		}

		.screenBg {
			// background-image: url("~@/assets/images/exposition/dummyBg.png");
			background-repeat: no-repeat;
			background-size: 101% 101%;
			background-position: -20px -20px;
			.cover-home {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background: url('~@/assets/images/exposition/dummyBg.png') no-repeat;
				background-size: 101% 102%;
				background-position: -20px -20px;
				z-index: 1;
			}
		}

		.screen {
			overflow: hidden;
			display: inline-block;
			width: 3840px;
			height: 1590px;
			transform-origin: 0 0;
			position: absolute;
			// left: 50%;

			.dummynotice-box {
				width: 1500px;
				height: 180px;
				position: absolute;
				top: 160px;
				left: 1035px;
				z-index: 5;
			}
			.dummy-footer-box{
				position: absolute;
				left: 800px;
				bottom: 40px;
				z-index: 10;
			}
			.dummy-left {
				width: 820px;
				position: absolute;
				left: 60px;
				top: 150px;
				height: 1470px;
				z-index: 2;

				.page-left {
					height: 1440px;
					width: 820px;

					.page-part {
						height: 480px;
						width: 820px;
					}
				}
			}

			.dummy-right {
				z-index: 2;
				width: 1500px;
				position: absolute;
				right: 0;
				top: 180px;
				height: 1440px;

				.page-right {
					.page-part-1 {
						height: 240px;
					}
				}
			}

			.dummyfooter-box {
				position: absolute;
				bottom: 0;
				left: 800px;
				width: 1500px;
				z-index: 5;
			}
		}
	}
</style>