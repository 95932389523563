<template>
  <!-- <div id="container"></div> -->
  <amap :center.sync="center" :zoom="zoom" :style="{ opacity: opacityShow }">
    <amap-map-type
      :visible="visible"
      :showTraffic="false"
      :showRoad="false"
      :defaultType="1"
    />
    <div v-for="(item, index) in clusterData" :key="index">
      <div>
        <amap-marker :position.sync="item.value" :anchor="anchor" :offset="[-50,-50]">
          <!-- :icon="car.icon"
					:offset="car.offset" -->
          <div v-if="item.actual_income && item.vehicles">
            <div
              style="height: 200px; width: 280px; padding-top: 10px"
              class="marsMapBgAll1 flex flex-direction"
              @click="clickArea(item)"
            >
              <div class="name">{{ item.name }}</div>
              <div class="vehicles">
                <div class="flex align-center align-items">
                  年调响应量 <span class="number">{{ item.vehicles }}</span> MW
                </div>
              </div>
              <!-- <div class="m-r-35 font-20 flex align-center">
                <div class="flex align-center">
                  年度调用收益：{{ item.actual_income }}万元
                </div>
              </div> -->
            </div>
          </div>
        </amap-marker>
      </div>
    </div>
  </amap>
</template>

<script>
// import AMapLoader from '@amap/amap-jsapi-loader'
import { dataInterface } from "@/api/dataInterfaceApi";
import area_diamond from "@/assets/images/index/area_diamond.png";
import area_diamond2 from "@/assets/images/index/area_diamond2.png";
import car_bg from "@/assets/images/index/car_bg.png";
import car_chongdian from "@/assets/images/index/car_chongdian.jpg";
export default {
  components: {},
  data() {
    return {
      plugin: [
        {
          pName: "MapType",
          defaultType: 1, //0代表默认，1代表卫星
          showTraffic: false, // 路网
          showRoad: true, // 路况
        },
      ],
      center: [115, 32],
      clusterData: [],
      anchor: "center",
      visible: false,
      params: {
        longitudeStart: "",
        latitudeStart: "",
        vehicleVin: "",
      },
      listDatas: [],
      car: {
        // path[0].slice(0)
        // pos: path[0].slice(0), // 小车icon的坐标，为空不能设置该key.
        // angle: (Math.atan2(dy, dx) * 180) / Math.PI,
        icon: {
          image: "https://jimnox.gitee.io/amap-vue/assets/car.png",
          imageSize: [26, 50],
        },

        offset: [-13, -25],
      },
      geoJson: {
        features: [],
      },
      zoom: 5,
      area_diamond: area_diamond,
      area_diamond2: area_diamond2,
      car_bg: car_bg,
      car_chongdian: car_chongdian,
      opacityShow: 0,
    };
  },
  created() {
    this.zoom = this.$route.query.zoom * 1 || 5;
    this.center = this.$route.query.center || [125, 32];
    let cityCode = this.$route.query.province
      ? this.$route.query.province
      : this.$route.query.city;
    console.log(this.$route.query.level, "级别");
    if (this.$route.query.level == "province") {
      console.log("当前-省");
      this.selectProvinceStationInfo(cityCode);
    } else if (this.$route.query.level == "city") {
      console.log("当前-市");
      this.selectCityStationInfo(cityCode);
    } else if (this.$route.query.level == "district") {
      console.log("当前-区");
      // this.$router.push({
      //     path: "/newVehicleControl",
      //     query: {
      //       city: item.cityCode,
      //       // name: item.name,
      //       cityName: item.name,
      //       value:item.value,
      //       zoom: 13,
      //       level: item.level,
      //       center: item.value
      //     },
      //   });
    } else {
      this.init();
    }
  },
  mounted() {},
  methods: {
    // clickMyVehical(item) {
    // 	console.log(item)
    // 	localStorage.setItem('newVehicleNum', item.vin)
    // 	if(item.vehicleType === '乘用车') {
    // 		this.$router.push({
    // 		    path: '/singleVehicle',
    // 		    query: {}
    // 		})
    // 	} else {
    // 		this.$router.push({
    // 		    path: '/singlezhongka',
    // 		    query: {}
    // 		})
    // 	}

    // },
    clickArea(item) {
      console.log(item, "这是点击的item");
      localStorage.setItem("updateDummyPcode", item.cityCode);
      this.$bus.$emit("updateDummyFn");
      // if (item.level == "province") {
      //   this.$router.push({
      //     path: "/changingElectricity",
      //     query: {
      //       province: item.cityCode,
      //       name: item.name,
      //       zoom: 8,
      //       level: item.level,
      //       center: item.value,
      //     },
      //   });
      // } else if (item.level == "city") {
      //   this.$router.push({
      //     path: "/changingElectricity",
      //     query: {
      //       city: item.cityCode,
      //       name: item.name,
      //       zoom: 13,
      //       level: item.level,
      //       center: item.value,
      //       value: item.value,
      //     },
      //   });
      // } else if (item.level == "district") {
      //   this.selectDistrictStationInfo("", item.cityCode);
      // } else if (item.station_type) {
      //   this.$router.push({
      //     path: "/powerStation",
      //     query: {
      //       stationId: item.id,
      //     },
      //   });
      // }
    },
    init(city, vehicleVin) {
      // let that = this;
      console.log(city);
      // that.center = value;
      this.params.vehicleVin = vehicleVin ? vehicleVin : "";
      this.selectCountryStationInfo();
    },
    //获取全国站点数据
    selectCountryStationInfo() {
      let params = {
        enCode: "powerplant-mid-ndyrlhsy", //TiDB-全国地图
        "@ADCODE": "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          console.log("虚拟电厂全国地图", res);
          // this.provinceStationInfo = res;
          // this.listDatas = res[0];
          this.getGeoJson(100000, "", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取省级站点数据
    selectProvinceStationInfo(updateParams) {
      console.log(updateParams, "updateParams");
      //获取省的站点信息
      let params = {
        enCode: "TiDB-shengjiditu-zhandianzhuangtai", //TiDB-全国地图
        "@province": updateParams,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          // this.getGeoJson(updateParams.province, "province", res);
          this.getGeoJson(updateParams, "province", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取区级站点数据
    selectCityStationInfo(updateParams) {
      //获取省的站点信息
      let params = {
        enCode: "TiDB-shijiditu-zhandianzhuangtai", //TiDB-全国地图
        "@province": updateParams,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          // this.getGeoJson(adcode, type, res);
          console.log(res, " 区级");
          this.getGeoJson(updateParams, "city", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取区级换电站数据
    selectDistrictStationInfo(adcode, curAdcode) {
      let params = {
        enCode: "TiDB-zhandian-zhandianjingweidu", //TiDB-全国地图
        "@province": curAdcode,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          //   this.stationList = res || [];
          //   console.log(res, "区级站点数据");

          this.clusterData = res.map((item) => {
            return {
              value: [item.longitude, item.latitude],
              houseTotalBatteries: item.houseTotalBatteries,
              id: item.id,
              stationName: item.stationName,
              station_type: item.station_type,
            };
          });
          console.log(this.clusterData, "区级站点数据");
          // this.getGeoJson1(adcode, curAdcode, res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGeoJson(adcode, type, stationInfo) {
      // type： province||city
      let that = this;
      /* eslint-disable */
      AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
        var districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
          if (error) {
            console.error(error);
            return;
          }
          let Json = areaNode.getSubFeatures();
          if (Json.length > 0) {
            console.log(Json, "Json");
            that.geoJson.features = Json;
          } else if (Json.length === 0) {
            that.geoJson.features = that.geoJson.features.filter(
              (item) => item.properties.adcode == adcode
            );
            if (that.geoJson.features.length === 0) return;
          }
          that.getMapData(type, stationInfo);
        });
      });
    },
    //获取数据
    async getMapData(type, stationInfo) {
      stationInfo = stationInfo.reduce((res, item, index, arr) => {
        if (!res[item.adcode || item.district || item.city]) {
          res[item.adcode || item.district || item.city] = item;
        } else {
          res[item.adcode || item.district || item.city] = {
            ...item,
          };
        }
        if (index === arr.length - 1) {
          return Object.values(res);
        }
        return res;
      }, []);

      let mapData = this.geoJson.features.map((item) => {
        //可以改造数据
        let data = {
          name: item.properties.name,
          //数据value 需要改
          datas: 0,
          position: [],
          cityCode: item.properties.adcode,
          level: item.properties.level, //省 province ，市city ，区district
          value: item.properties.center,
        };
        let currentData = [];
        currentData = stationInfo.filter(
          (item) => (item.adcode || item.district || item.city) == data.cityCode
        );

        if (currentData && currentData.length > 0) {
          data.vehicles = currentData[0].cumulative_response_capacity;
          data.datas = currentData[0].cumulative_response_capacity;
          data.actual_income = currentData[0].actual_income;
          data.value = item.properties.center;
        }

        // console.log("data", data);
        return data;
      });

      console.log("虚拟电厂mapData", mapData);
      this.clusterData = mapData;

      this.opacityShow = 1;
    },
  },
  filters: {},
  // 销毁定时器
  beforeDestroy: function () {},
};
</script>
<style>
.amap-logo {
  display: none !important;
}

.amap-copyright {
  visibility: hidden !important;
}

.amap-icon {
  display: none;
}

.amap-maptypecontrol {
  display: none !important;
}
</style>



<style scope lang="scss">
@import "@/assets/scss/comm.scss";
.marsMapBgAll1 {
  width: 188px;
  height: 138px;
  background: url("~@/assets/images/index/smallGreen1.png") repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  padding-top: 30px;
}

.m-r-40 {
  margin-left: 25px;
}

.m-r-25 {
  margin-left: 25px;
}

.m-r-35 {
  margin-left: 35px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 22px;
}

.m-b-20 {
  margin-bottom: 10px;
}

.number {
  font-size: 34px;
  @include ffamily-DS;
  color: #00FDF4;
}
</style>

<style scoped>
.amap-logo {
  display: none !important;
}

.amap-copyright {
  visibility: hidden !important;
}

.name {
  width: 100%;
  height: 40px;
  font-size: 28px;
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}

.vehicles {
  width: 100%;
  height: 40px;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 20px;
}


</style>