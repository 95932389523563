<template>
  <div style="width: 790px; height: 380px">
    <MyEcharts :visible="showcdata" :id="'dummytiaojieqianli'" :options="cdata" />
  </div>
</template>

<script>
// import divBox2 from '@/views/mobile/components/divbox2'
import MyEcharts from "@/views/components/charts/echarts.vue";
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    // divBox2,
    MyEcharts,
  },
  data() {
    return {
      showcdata: false,
      // title:"车辆新增及活跃趋势",
      cdata: {
        title: {
						text: "MW",
						left:-5,
            top: -2
					},
        // color: ['#0A9ABE','#FFD15C','#54CBCD','#FFD15C'],
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
              // data: ['发电量', '收益', '周平均发电量', '周平均收益'],
            },
          },
        },
        grid: {
          left: "0%", // 左边距
          right: "5%", // 右边距
          bottom: "", // 底边距
          top: "10%", // 顶边距
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          // name: "MW",
          type: "value",
          splitLine: true,
        },
        // ,{
        // 	name: '万元',
        // 	type: 'value',
        // 	splitLine: true,
        // 	itemStyle: {
        // 		textAlign: 'center'
        // 	}
        // }
        series: [
          {
            name: "日前",
            type: "line",
            smooth: true,
            data: [],
            barWidth: 5,
            lineStyle: {
              color: "#6dfbfe",
            },
            areaStyle: {
              opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#6dfbfe",
                },
                {
                  offset: 1,
                  color: "#183b3d",
                },
              ]),
            },
            // tooltip: {
            // 	valueFormatter: function (value) {
            // 		return value + ' kWh';
            // 	}
            // },
          },
          {
            name: "小时",
            type: "line",
            smooth: true,
            data: [],
            lineStyle: {
              color: "#ffd15d",
            },
            barWidth: 5,
            areaStyle: {
              opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#f2c585",
                },
                {
                  offset: 1,
                  color: "#51432e",
                },
              ]),
            },
            // tooltip: {
            // 	valueFormatter: function (value) {
            // 		return value + ' 元';
            // 	}
            // },
          },
          {
            name: "分钟",
            type: "line",
            smooth: true,
            barWidth: 5,
            lineStyle: {
              color: "#D4691C",
            },
            areaStyle: {
              opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#D4691C",
                },
                {
                  offset: 1,
                  color: "#492913",
                },
              ]),
            },
            // tooltip: {
            // 	valueFormatter: function (value) {
            // 		return value + ' kWh';
            // 	}
            // },
          },
          {
            name: "秒级",
            type: "line",
            smooth: true,
            data: [],
            barWidth: 5,
            lineStyle: {
              color: "#659bf1",
            },
            areaStyle: {
              opacity: 0.4,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#659bf1",
                },
                {
                  offset: 1,
                  color: "#1c2d46",
                },
              ]),
            },
          },
        ],
      },
    };
  },
  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getBattery24hours();
    });
    this.getBattery24hours();
  },
  methods: {
    //获取24小时电次数分布
    getBattery24hours() {
      let params = {
        enCode: "powerplant-left-tjql",
        // "@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
        // "@dim_date": localStorage.getItem("changeDataNuber") || timer,
      };
      this.showcdata = false;
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "调节潜力");
          if (res) {
            let data = [];
            let data2 = [];
            let xAxis = [];
            let data3 = [];
            let data4 = [];
            res.forEach((item) => {
              xAxis.push(item.province);
              data.push(item.day_regulate_potential);
              data2.push(item.hour_regulate_potential);
              data3.push(item.minute_regulate_potential);
              data4.push(item.second_regulate_potential);
            });
            console.log(data);
            this.cdata.xAxis.data = xAxis;
            this.cdata.series[0].data = data;
            this.cdata.series[1].data = data2;
            this.cdata.series[2].data = data3;
            this.cdata.series[3].data = data4;
            this.showcdata = true;
            console.log(this.showcdata, "调节潜力");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
.main-box-diangganggaikuang {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .main-box-cell {
    margin-bottom: 6px;
    display: flex;
    width: 200px;
    height: 100px;
    padding: 0 10px;

    .main-box-left {
      img {
        width: 80px;
        height: 80px;
      }
    }

    .main-box-right {
      padding-left: 15px;

      .top {
        height: 50px;
        line-height: 70px;
      }

      .bottom {
        height: 50px;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
</style>