<template>
	<div class="my-footer">
		<div class="notice-box notice-box-1">
			<div class="xuqiuxiangying">
				<div class="round3">
					<img src="@/assets/images/exposition/round3.png" alt="">
				</div>
				<div class="round2">
					<img src="@/assets/images/exposition/round2.png" alt="">
				</div>
				<div class="xuqiuxiangyingBg">
					<img src="@/assets/images/exposition/xuqiuxiangying.png" alt="">
				</div>

			</div>
			<div class="notice-box-title">需求响应</div>
			<div class="notice-box-cell DS-Digital-Bold" v-for="(item, i) in target" :key="'target' + i">
				{{ item.label }} <br />
				<span class="number">{{ item.number }}</span> <span class="unit">{{ item.unit }}</span>
			</div>
		</div>
		<div class="notice-box notice-box-2">
			<div class="fuzhufuwu">
				<div class="round3">
					<img src="@/assets/images/exposition/round3.png" alt="">
				</div>
				<div class="round2">
					<img src="@/assets/images/exposition/round2.png" alt="">
				</div>
				<div class="fuzhufuwuBg">
					<img src="@/assets/images/exposition/fuzhufuwu.png" alt="">
				</div>

			</div>
			<div class="notice-box-title">辅助服务</div>
			<div class="notice-box-cell DS-Digital-Bold" v-for="(item, i) in target1" :key="'target' + i">
				{{ item.label }} <br />
				<span class="number"> {{ item.number }}</span> <span class="unit">{{ item.unit }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dataInterface
	} from "@/api/dataInterfaceApi";
	export default {
		components: {},
		data() {
			let mIcon = require("@/assets/images/exposition/juheshang.png");
			return {
				photovoltaic: {},
				now: "日",
				target: [{
						icon: mIcon,
						label: "需求响应邀约",
						number: "",
						unit: "个",
					},
					{
						icon: mIcon,
						label: "执行主体数量",
						number: "",
						unit: "个",
					},
					{
						icon: mIcon,
						label: "执行指令下发",
						number: "",
						unit: "条",
					},
					{
						icon: mIcon,
						label: "有效节点率",
						number: "",
						unit: "%",
					},
					// {
					// 	icon: mIcon,
					// 	label: '收益测算',
					// 	number: '',
					// 	unit: '万元'
					// }
				],
				target1: [{
						icon: mIcon,
						label: "辅助服务邀约",
						number: "",
						unit: "个",
					},
					{
						icon: mIcon,
						label: "执行主体数量",
						number: "",
						unit: "个",
					},
					{
						icon: mIcon,
						label: "执行命令下发",
						number: "",
						unit: "条",
					},
					{
						icon: mIcon,
						label: "有效节点率",
						number: "",
						unit: "%",
					},
					// {
					// 	icon: mIcon,
					// 	label: '收益测算',
					// 	number: '',
					// 	unit: '万元'
					// }
				],
				province: "320000",
				show1: false,
				show2: false,
			};
		},
		destroyed() {
			this.$bus.$off("updateDummyFn");
		},
		mounted() {
			this.$bus.$on("updateDummyFn", () => {
				this.getphotovoltaic();
				this.getData();
			});
			this.getphotovoltaic();
			this.getData();
		},
		methods: {
			nowType() {
				return localStorage.getItem("typeTime") || "日";
			},
			getphotovoltaic() {
				this.show1 = false;
				let params = {
					enCode: "powerplant-mid-xuqiuxiangying",
					"@province": localStorage.getItem("updateDummyPcode") ?
						localStorage.getItem("updateDummyPcode") : this.province,
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						console.log(res, "响应主体分布");
						let data = res[0] || {};
						this.target[0].number = data.invitation_cnts || 0;
						this.target[1].number = data.associated_cnts || 0;
						this.target[2].number = data.executive_instruction_delivery || 0;
						this.target[3].number = data.effective_annual_node_rate || 0;
						this.target[4].number = data.cumulative_subsidy || 0;
						this.show1 = true;
					})
					.catch((err) => {
						console.log(err);
					});
			},
			getData() {
				this.show2 = false;
				let params = {
					enCode: "powerplant-mid-fuzhufuwu",
					"@province": localStorage.getItem("updateDummyPcode") ?
						localStorage.getItem("updateDummyPcode") : this.province,
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						console.log(res, "响应主体分布111111111111111111111");
						console.log(this.target1, "响应主体分布111111111111111111111");
						let data = res[0] || {};
						this.target1[0].number = data.invitation_cnts || 0;
						this.target1[1].number = data.associated_cnts || 0;
						this.target1[2].number = data.executive_instruction_delivery || 0;
						this.target1[3].number = data.effective_annual_node_rate || 0;
						this.target1[4].number = data.cumulative_subsidy || 0;
						this.show2 = true;
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@font-face {
		font-family: "DS-Digital-Bold";
		src: url("~@/assets/font/DS-Digital-Bold.ttf");
	}

	.DS-Digital-Bold {
		font-family: "DS-Digital-Bold";
	}

	.notice-box-1 {
		background: url('~@/assets/images/exposition/xuqiuxiangyingBg.png') no-repeat;

		.xuqiuxiangying {
			width: 160px;
			height: 148px;
			position: relative;

			.round2 {
				position: absolute;
				top: 12px;
				left: 11px;
				width: 65px;
				height: 65px;

				@keyframes animal {
					0%{transform:rotate(0deg);}
					
					50%{transform:rotate(180deg);}
					
					100%{transform:rotate(360deg);}
				}

				img {
					-webkit-animation: animal 4s infinite linear;
					display: block;
					width: 125px;
					height: 125px;
				}
			}

			.round3 {
				position: absolute;
				top: 12px;
				left: 11px;
				width: 65px;
				height: 65px;

				img {
					display: block;
					width: 125px;
					height: 125px;
				}
			}

			.xuqiuxiangyingBg {
				position: absolute;
				top: 43px;
				left: 41px;
				width: 65px;
				height: 65px;

				img {
					display: block;
					width: 65px;
					height: 65px;
					z-index: 100001;
				}
			}


		}
	}

	.notice-box-2 {
		background: url('~@/assets/images/exposition/xuqiuxiangyingBg.png') no-repeat;

		.fuzhufuwu {
			width: 160px;
			height: 148px;
			position: relative;

			
			.round2 {
				position: absolute;
				top: 12px;
				left: 11px;
				width: 65px;
				height: 65px;
			
				@keyframes animal {
					0%{transform:rotate(0deg);}
					
					50%{transform:rotate(180deg);}
					
					100%{transform:rotate(360deg);}
				}
			
				img {
					-webkit-animation: animal 4s infinite linear;
					display: block;
					width: 125px;
					height: 125px;
				}
			}
			.round3 {
				position: absolute;
				top: 12px;
				left: 11px;
				width: 65px;
				height: 65px;
			
				img {
					display: block;
					width: 125px;
					height: 125px;
				}
			}

			.fuzhufuwuBg {
				position: absolute;
				top: 43px;
				left: 41px;
				width: 65px;
				height: 65px;

				img {
					display: block;
					width: 65px;
					height: 65px;
					z-index: 100001;
				}
			}
		}
	}

	.notice-box-1,
	.notice-box-2,
	.notice-box {
		width: 1403px;
		// width: 100%;
		height: 148px;
		margin-bottom: 30px;
		margin-left: 70px;
		// background: url('~@/assets/images/exposition/dummyNotice.png') no-repeat;
		background-size: 100% 100%;
		font-size: $FontSize36;
		// padding-left: 160px;
		display: flex;

		.notice-box-title {
			width: 190px;
			line-height: 150px;
			// background-color: pink;
			text-align: center;
			padding-right: 10px;
		}

		.notice-box-cell {
			width: 270px;
			// text-align: center;
			font-size: $FontSize32;
			margin-top: 40px;
			padding: 0 10px 0 40px;
			background: url("~@/assets/images/exposition/link.png") no-repeat;
			background-size: 23px 55px;
			background-position: 7px 19px;
		}

		.number,
		.unit {
			color: #00FDF4;
		}
	}
</style>