<template>
    <div
        class="myTitle-tem flex align-items"
        :class="{'text-2': type == 2}">
        <h3 class="flex align-items">
            <span class="icon rotate"></span>
            {{ title }}
            <dv-decoration-2 v-if="type == 2" style="width: 100%; height:5px;" />
        </h3>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: [Number, String],
            default: 1
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.myTitle-tem {
    width: 100%;
    height: 80px;
    font-weight: bold;
    // text-shadow: 0px 0px 9px #03B9BD;
    color: #f0f0f0;
    @include ffamily-YH;
    > h3 {
        background: url(~@/assets/images/myComm/title1.png) left bottom no-repeat;
        background-size: 100%;
        padding: 0 20px 0 80px;
        position: relative;
        white-space: nowrap;
        display: inline-block;
        font-size: $FontSize50;
        &::before {
            position: absolute;
            content: '';
            width: 80px;
            height: 70px;
            top: 0;
            left: 0;
            background: url(~@/assets/images/myComm/title-bg.png) left bottom no-repeat;
            background-size: 100%;
        }
        .icon {
            display: none;
        }
    }
    &.text-2 {
        height: 66px;
        padding: 0 0 0 50px;
        @include ffamily-YH;
        > h3 {
            background: url(~@/assets/images/myComm/title2.png) left bottom no-repeat;
            background-size: 100% 44px;
            padding: 0 80px 0 0;
            font-size: $FontSize30;
            &::before {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                top: 0;
                left: 0;
                background: none;
            }
            .icon {
                display: block;
                width: 40px;
                height: 40px;
                background: url(~@/assets/images/myComm/title2-1.png) left bottom no-repeat;
                background-size: 100%;
                position: absolute;
                left: -50px;
                top: 5px;
            }
        }
    }
}
.rotate {
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>